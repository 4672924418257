import IqButton from '@/UI/buttons/IqButton'
import IqInputForm from '@/UI/form/input/IqInputForm'
import MainCheckbox from '@/UI/checkbox/IqCheckboxForm'

import { GET_IS_AUTH_LOADING, GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'
import Api from '@/api'
import rulesMixin from '@/views/login/data/rulesMixin'
export default {
  components: { MainCheckbox, IqButton, IqInputForm },
  mixins: [rulesMixin],
  data() {
    return {
      formData: {
        name: '',
        surname: '',
        phone: '',
        password: '',
        passwordCheck: '',
        privacyPolicy: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
      isLoading: GET_IS_AUTH_LOADING,
    }),
  },
  methods: {
    handleReg(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let formData = new FormData()

          formData.append('phone', this.formData.phone)
          try {
            const { data } = await Api.auth.sms(formData)

            data.name = this.formData.name
            data.surname = this.formData.surname
            data.password = this.formData.password
            this.$emit('code-check', data)
          } catch (err) {
            console.log(err)
          }
        } else {
          console.log('Error')
        }
      })
    },
  },
}
